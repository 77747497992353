/* You can add global styles to this file, and also import other style files */
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0px;
  background-color: #e5e5e5;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 20px;
}

p {
  font-size: 12px;
}
